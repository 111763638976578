import React from 'react';

import Layout from '../components/Layout';
import Link from '../components/Link';
import Helmet from '../components/Helmet';

function NotFound() {
  return (
    <Layout>
      <div className="ft-grid ft-gap-margin ft-gap-margin--col ft-gap-margin--lg">
        <Helmet
          path="/404"
          title="404 – Seite nicht gefunden"
          description="Die angeforderte Internetadresse existiert leider nicht."
        />
        <h1>404 – ‍Seite nicht gefunden</h1>
        <div>
          <p><span className="ft-font--xl" role="img" aria-label="Shrugging">🤷</span></p>
          <p>
            Vielleicht versuchst du es mal mit der
            {' '}
            <Link to="/">Startseite</Link>
            {' '}
            oder der Suche unten? 👇
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default NotFound;
